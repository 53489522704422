import React from "react"
import {DelayContext} from "./DelayContext"

const useDelayContext = () => {
  const context = React.useContext(DelayContext)
  if (context == null) {
    throw new Error("useDelayContext() must be used in <Delay/>")
  }
  return context
}
export {useDelayContext}
