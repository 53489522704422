import React from "react"
import {Validator} from "../../models/Validator"

interface WeitereValidatorInformationenProps {
  validatoren: Validator[]
}

const validatorModus = (modus: string): string => {
  switch (modus) {
    case "ERROR":
      return "Fehler"
    case "WARNING":
      return "Warnung"
    case "HINT":
      return "Hinweis"
    default:
      return "Modus konnte nicht ermittelt werden"
  }
}

const WeitereValidatorInformationen = ({validatoren}: WeitereValidatorInformationenProps) => (
  <ul>
    {validatoren.map((validator, index) => (
      <li key={`validator-${index}`}>
        <div className="entry-wrapper">
          <span className="entry">
            {validator.id} <br />
            Beschreibung: {validator.beschreibung} <br />
            Aktiv: {validator.aktiv ? "Ja" : "Nein"} <br />
            Modus: {validatorModus(validator.modus)} <br />
            Bereitstelldatum: {new Date(validator.bereitstelldatum).toLocaleDateString("de")}
          </span>
          <div className="clear" />
        </div>
      </li>
    ))}
  </ul>
)

export {WeitereValidatorInformationen}
