import * as React from "react"
import {ValidierungsAnfrageStatus} from "../../models/ValidierungsAnfrageResponse"
import {getRequestPlainStringResponse} from "../../services/HttpService"

//Komponente zur Eingabe der Validierungsparameter
interface ReportProps {
  report: string
  reportId: string | undefined
  updateProgressStatus: (status: ValidierungsAnfrageStatus) => void
}

export const ReportButtons: React.FC<ReportProps> = ({report, reportId, updateProgressStatus}) => {
  const [isDownloadingReport, setIsDownloadingReport] = React.useState(false)
  const [lastDownloadDate, setLastDownloadDate] = React.useState<number>(0)

  const minDownloadDurationSecs = 5

  let fileName: string | undefined

  const copyToClipboard = () => {
    navigator.clipboard.writeText(report)
  }

  const downloadReportAsync = () =>
    getRequestPlainStringResponse(`v2/${reportId}/komplettreport`, {
      handleResponseError: response => {
        updateProgressStatus("ERROR_INTERNAL_BACKEND")
        if (response.status === 404) {
          updateProgressStatus("ERROR_VALIDATION_ID_NOT_FOUND")
        }
        if (response.status === 428) {
          updateProgressStatus("ERROR_REPORT_REQUEST_BEFORE_FINISHED")
        }
      },
      handleCaughtError: () => {
        updateProgressStatus("ERROR_NO_BACKEND_RESPONSE")
      }
    })
      .then(response => {
        if (response) {
          fileName = response.headers.get("content-disposition")?.split("filename=")[1]
          return response.text()
        }
      })
      .then(text => {
        if (text) {
          const file = new Blob([text], {type: "text/plain"})
          const downloadNode = document.createElement("a")
          downloadNode.setAttribute("href", URL.createObjectURL(file))
          downloadNode.setAttribute("download", fileName ? fileName : "Validator_Komplettreport.txt")
          document.body.appendChild(downloadNode)
          downloadNode.dispatchEvent(new MouseEvent("click", {bubbles: true, cancelable: true, view: window}))
          downloadNode.remove()
          return new Promise(resolve => setTimeout(resolve, 500))
        }
      })

  const downloadReport = () => {
    if (isDownloadingReport) {
      return
    }

    setIsDownloadingReport(true)
    setLastDownloadDate(Date.now())
    downloadReportAsync()
      .then(() => {
        const now = Date.now()
        const t = new Date(lastDownloadDate + minDownloadDurationSecs * 1_000).getTime()
        if (now < t) {
          return new Promise(resolve => setTimeout(resolve, t - now))
        }
        return Promise.resolve()
      })
      .catch(e => {
        console.error("Error while downloading report", e)
      })
      .finally(() => setIsDownloadingReport(false))
  }

  return (
    <div style={{float: "right"}}>
      <button className="report-button copy-button" onClick={copyToClipboard}>
        Report in Zwischenablage kopieren
      </button>
      <button className="report-button download-button" onClick={downloadReport} disabled={isDownloadingReport}>
        {isDownloadingReport ? "Download läuft, bitte warten..." : "Vollständigen Report herunterladen"}
      </button>
    </div>
  )
}
