import Keycloak from "keycloak-js"

let memoizedKeycloak: Promise<Keycloak>

export async function getKeycloak(): Promise<Keycloak> {
  if (memoizedKeycloak) {
    return memoizedKeycloak
  }
  memoizedKeycloak = initializeKeycloak()
  return memoizedKeycloak
}

async function initializeKeycloak(): Promise<Keycloak> {
  const keycloak = new Keycloak({
    url: window.env.KEYCLOAK_SERVER_URL,
    realm: window.env.KEYCLOAK_REALM,
    clientId: window.env.KEYCLOAK_CLIENT_ID
  })

  try {
    await keycloak.init({
      onLoad: "login-required"
    })
  } catch (error) {
    console.error("Failed to initialize adapter:", error)
  }
  return keycloak
}
