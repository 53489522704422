import {Validator} from "./Validator"

export interface ConfigInfoResponse {
  backendVersion: string
  fachvalidatorVersion: string
  xzufiVersionen: string[]
  validatoren: Validator[]
}

export const defaultConfigInfoResponse: ConfigInfoResponse = {
  backendVersion: "Version konnte nicht ermittelt werden",
  fachvalidatorVersion: "Version konnte nicht ermittelt werden",
  xzufiVersionen: [],
  validatoren: []
}
