import {getKeycloak} from "../keycloak/keycloak"

const baseUrl = window.env.BACKEND_URL + "/"

export interface RequestOptions {
  handleResponseError?: (response: Response) => void
  handleCaughtError?: (error: unknown) => void
  headers?: Record<string, string>
  skipAuth?: boolean
}

const executeRequest = async <T>(
  url: string,
  method: string,
  options: RequestOptions,
  responseBodyParser: (response: Response) => Promise<T | null>,
  requestBody?: BodyInit
): Promise<T | null> => {
  const headers = options.headers || {}
  if (!options.skipAuth) {
    const keycloak = await getKeycloak()
    const isRefreshed = await keycloak.updateToken(30)
    console.debug("is token refreshed", isRefreshed)
    headers.authorization = `Bearer ${keycloak.token}`
  }
  try {
    const response = await fetch(`${baseUrl}${url}`, {
      method,
      headers,
      body: requestBody
    })
    if (response.ok) {
      return responseBodyParser(response)
    }
    options.handleResponseError?.(response)
  } catch (error) {
    if (options.handleCaughtError) {
      options.handleCaughtError?.(error)
    } else {
      console.error(error)
    }
  }
  return null
}

const parseResponseBody = async <T>(response: Response): Promise<T | null> => (await response.json()) as T

const getResponseText = async (response: Response): Promise<string | null> => response.text()

export const getRequest = <T>(url: string, options?: RequestOptions): Promise<T | null> => {
  return executeRequest<T>(url, "GET", options || {}, parseResponseBody<T>)
}

export const getRequestPlainString = async (url: string, options: RequestOptions = {}): Promise<string | null> =>
  executeRequest<string>(
    url,
    "GET",
    {
      ...options,
      headers: {
        ...(options.headers || {}),
        accept: "text/plain"
      }
    },
    getResponseText
  )

export const getRequestPlainStringResponse = async (
  url: string,
  options: RequestOptions = {}
): Promise<void | Response | null> =>
  executeRequest<Response>(
    url,
    "GET",
    {
      ...options,
      headers: {
        ...(options.headers || {}),
        accept: "text/plain"
      }
    },
    async response => response
  )

export const deleteRequest = async <T>(url: string, options: RequestOptions = {}): Promise<T | null> =>
  executeRequest(url, "DELETE", options, parseResponseBody<T>)

/**
 * T: Type des Response Models
 */
export const postRequest = async <T>(url: string, data: unknown, options: RequestOptions = {}): Promise<T | null> =>
  executeRequest(
    url,
    "POST",
    {
      ...options,
      headers: {
        ...(options.headers || {}),
        "Content-Type": "application/json"
      }
    },
    parseResponseBody<T>,
    JSON.stringify(data)
  )
