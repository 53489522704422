import React from "react"
import {useDelayContext} from "../../context/useDelayContext"
import {ValidierungsAnfrageResponse, ValidierungsAnfrageStatus} from "../../models/ValidierungsAnfrageResponse"
import {useInterval} from "./useInterval"
import {getRequest, getRequestPlainString} from "../../services/HttpService"

const startProgressState: ValidierungsAnfrageResponse = {
  status: "INIT",
  id: "",
  gesamt: 0,
  validiert: 0,
  fehlerhaft: 0,
  warnungen: 0
}

export const useValidationProgress = (onFinished: () => void) => {
  const [progress, setProgress] = React.useState<ValidierungsAnfrageResponse>(startProgressState)
  const [report, setReport] = React.useState<string | undefined>()
  const [reportWirdGeneriert, setReportWirdGeneriert] = React.useState<boolean>(false)
  const [isRunning, setIsRunning] = React.useState(false)
  const [rsId, setRsId] = React.useState<string | undefined>()
  const [waitingForRequest, setWaitingForRequest] = React.useState(false)

  const {delay, incrementDelay, resetRequestDelay, isMaxDelay} = useDelayContext()

  const fortschrittsAbfrage = () => {
    getRequest<ValidierungsAnfrageResponse>(`v1/${rsId}/fortschritt`, {
      handleResponseError: response => {
        if (response.status === 404) {
          updateProgressStatus("ERROR_VALIDATION_ID_NOT_FOUND")
        } else {
          updateProgressStatus("ERROR_INTERNAL_BACKEND")
        }
        endPolling()
      },
      handleCaughtError: () => {
        if (isMaxDelay()) {
          updateProgressStatus("ERROR_CONNECTION_LOST")
          endPolling()
        } else {
          updateProgressStatus("TRYING_TO_RECONNECT")
          incrementDelay()
        }
      }
    })
      .then(response => {
        if (response) {
          // reset delay if connection could be reestablished
          if (progress.status === "TRYING_TO_RECONNECT") {
            resetRequestDelay()
          }

          setProgress(response)

          // Validierung fertig Rufe den endgültigen Report ab
          if (response.status === "FINISHED") {
            onFinished()
            setReportWirdGeneriert(true)
          }
        }
      })
      .finally(() => {
        setWaitingForRequest(false)
      })
  }

  const reportAbfrage = () => {
    getRequestPlainString(`v3/${rsId}/report`, {
      handleResponseError: response => {
        if (response.status === 404) {
          updateProgressStatus("ERROR_VALIDATION_ID_NOT_FOUND")
        } else if (response.status === 428) {
          updateProgressStatus("ERROR_REPORT_REQUEST_BEFORE_FINISHED")
        } else {
          updateProgressStatus("ERROR_INTERNAL_BACKEND")
        }
        endPolling()
      },
      handleCaughtError: () => {
        if (isMaxDelay()) {
          updateProgressStatus("ERROR_CONNECTION_LOST")
          endPolling()
        } else {
          updateProgressStatus("TRYING_TO_RECONNECT")
          incrementDelay()
        }
      }
    })
      .then(result => {
        if (result) {
          // reset delay if connection could be reestablished
          if (progress.status === "TRYING_TO_RECONNECT") {
            resetRequestDelay()
          }

          setReport(result)
          setReportWirdGeneriert(false)
          setIsRunning(false)
        }
      })
      .finally(() => {
        setWaitingForRequest(false)
      })
  }

  useInterval(
    async () => {
      if (!rsId || waitingForRequest) {
        return
      }

      setWaitingForRequest(true)

      if (!reportWirdGeneriert) {
        fortschrittsAbfrage()
      } else {
        reportAbfrage()
      }
    },
    delay,
    isRunning
  )

  const reset = React.useCallback(() => {
    setReport(undefined)
    setReportWirdGeneriert(false)
    resetRequestDelay()
  }, [resetRequestDelay])

  const startPolling = React.useCallback(
    (rsId: string) => {
      reset()
      setIsRunning(true)
      setRsId(rsId)
    },
    [reset]
  )

  const endPolling = () => {
    reset()
    setIsRunning(false)
    setRsId(undefined)
  }

  const updateProgressStatus = React.useCallback(
    (status: ValidierungsAnfrageStatus) => setProgress(prevProgress => ({...prevProgress, status})),
    []
  )

  return {
    progress,
    report,
    reportWirdGeneriert,
    startPolling,
    endPolling,
    updateProgressStatus
  }
}
