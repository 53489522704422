import React from "react"
import {ValidierungsAnfrageResponse} from "../../models/ValidierungsAnfrageResponse"
import {FortschrittReconnectText} from "./FortschrittReconnectText"

interface FortschrittProps {
  data: ValidierungsAnfrageResponse
}
// Komponente zur Abfrage und Anzeige des aktuellen Validierungsfortschritts
export const Fortschritt: React.FC<FortschrittProps> = ({
  data: {gesamt: dataGesamt, validiert: dataValidiert, status, fehlerhaft: dataFehlerhaft}
}) => {
  const gesamt = dataGesamt === 1 ? "Dokument" : "Dokumente"
  const validierte = dataValidiert === 1 ? "Dokument" : "Dokumente"
  const fehlerhafte = dataFehlerhaft === 1 ? "fehlerhaftes Dokument" : "fehlerhafte Dokumente"
  const ueberpruefte =
    dataFehlerhaft === 1 ? "überprüftes Dokument ist fehlerhaft" : "überprüfte Dokumente sind fehlerhaft"

  if (status === "INIT") {
    return <>{"Warte auf den Start einer Validierung."}</>
  }
  if (status === "CANCELED") {
    return <>{"Validierung wurde abgebrochen."}</>
  }
  if (status === "WAITING") {
    return <>{"Ihre Validierungsanfrage wurde entgegengenommen. Die Validierung beginnt in Kürze."}</>
  }
  if (status === "CONNECTING_TO_BACKEND") {
    return <>{"Verbindungsaufbau..."}</>
  }
  if (status === "READING_INDEX") {
    return <>{"Lese Indexdatei..."}</>
  }
  if (status === "ERROR_NO_BACKEND_RESPONSE") {
    return <>{"Es kann keine Verbindung zum Server hergestellt werden."}</>
  }
  if (status === "ERROR_VALIDATION_ID_NOT_FOUND") {
    return (
      <>
        {
          "Der aktuelle Validierungsprozess konnte nicht gefunden werden. Das Validierungsergebnis ist wahrscheinlich nicht mehr vorhanden. Bitte starten Sie eine neue Validierung."
        }
      </>
    )
  }
  if (status === "ERROR_BAD_REQUEST") {
    return (
      <>
        {"Die Validierungsanfrage enthält ungültige Informationen. Möglicherweise ist die angegebene URL fehlerhaft."}
      </>
    )
  }
  if (status === "ERROR_INTERNAL_BACKEND") {
    return <>{"Es ist ein unbekanntes Problem aufgetreten."}</>
  }
  if (status === "ERROR_REPORT_REQUEST_BEFORE_FINISHED") {
    return <>{"Der Validatorreport wurde angefordert, obwohl die Validierung noch nicht beendet war."}</>
  }
  if (status === "TRYING_TO_RECONNECT") {
    return <FortschrittReconnectText />
  }
  if (status === "VALIDATING") {
    return (
      <>
        Validierung gestartet.
        <br />
        {dataValidiert}/{dataGesamt} {validierte} validiert.
        <br />
        {dataFehlerhaft} {fehlerhafte} gefunden.
      </>
    )
  }
  if (status === "FINISHED" && dataFehlerhaft) {
    return (
      <>
        Validierung beendet ({dataGesamt} {gesamt}).
        <br />
        {dataFehlerhaft} {ueberpruefte}.
      </>
    )
  }
  if (status === "FINISHED" && !dataFehlerhaft) {
    return (
      <>
        Validierung beendet ({dataGesamt} {gesamt}).
      </>
    )
  }
  if (status === "ERROR_CONNECTION_LOST") {
    return (
      <>
        Die Verbindung zum Server wurde verloren. Validierung abgebrochen.
        <br />
        {dataValidiert}/{dataGesamt} Dokumente validiert.
        <br />
        {dataFehlerhaft} fehlerhafte Dokumente gefunden.
      </>
    )
  }
  return (
    <>
      {
        "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen sie es später erneut oder wenden sie sich an einen Administrator"
      }
    </>
  )
}
