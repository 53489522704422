import React, {ChangeEvent} from "react"

interface BenutzerangabenProps {
  isAuthDisabled: boolean
  setIsAuthDisabled: (isAuthDisabled: boolean) => void
  setPassword: (password: string) => void
  setUsername: (username: string) => void
}

//Komponente zur Eingabe Username und Passwort
export const Benutzerangaben: React.FC<BenutzerangabenProps> = ({
  isAuthDisabled,
  setIsAuthDisabled,
  setPassword,
  setUsername: setUserName
}) => {
  const changeUserName = (event: ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value)
  }

  const changePassword = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  const changeVisibility = () => {
    setIsAuthDisabled(!isAuthDisabled)
  }

  return (
    <div className="auth-wrapper control-group">
      <div className="header-auth">Authentifizierung</div>
      <label className="benutzernamen-und-passwort-angeben control control-checkbox">
        <input type="checkbox" onChange={changeVisibility} />
        <div className="control_indicator"></div>
        Benutzernamen und Passwort angeben
      </label>
      <div />
      <div className="rectangle-auth">
        <div className="rectangle-auth-inner">
          <div>
            <label className="benutzername" htmlFor="benutzername-input">
              Benutzername:
            </label>
          </div>
          <div>
            <input
              id="benutzername-input"
              className="benutzername-input"
              type="text"
              placeholder="Benutzername eingeben"
              disabled={isAuthDisabled}
              onChange={changeUserName}
            />
          </div>
        </div>

        <div className="rectangle-auth-inner">
          <div>
            <label className="passwort" htmlFor="passwort-input">
              Passwort:
            </label>
          </div>
          <div>
            <input
              id="passwort-input"
              className="passwort-input"
              type="password"
              placeholder="Passwort eingeben"
              disabled={isAuthDisabled}
              onChange={changePassword}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
