import React from "react"

export interface RequestDelayState {
  delay: number
  incrementDelay: () => void
  resetRequestDelay: () => void
  isMaxDelay: () => boolean
  getTryCount: () => number
  maxRetryCount: number
}
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const DelayContext = React.createContext<RequestDelayState>(null!)
DelayContext.displayName = "DelayContext"
export {DelayContext}
