export const redaktionsSystemsArray = [
  {
    name: "Bund",
    redsyscode: "BUND",
    schemeAgencyId: "B100019"
  },
  {
    name: "Baden-Württemberg",
    redsyscode: "BW",
    schemeAgencyId: "L100022"
  },
  {
    name: "Bayern",
    redsyscode: "BY",
    schemeAgencyId: "L100042"
  },
  {
    name: "Berlin",
    redsyscode: "BE",
    schemeAgencyId: "L100108"
  },
  {
    name: "Brandenburg",
    redsyscode: "BB",
    schemeAgencyId: "L100041"
  },
  {
    name: "Bremen",
    redsyscode: "HB",
    schemeAgencyId: "S100003"
  },
  {
    name: "Hamburg",
    redsyscode: "HH",
    schemeAgencyId: "S100002"
  },
  {
    name: "Hessen",
    redsyscode: "HE",
    schemeAgencyId: "L100001"
  },
  {
    name: "Mecklenburg-Vorpommern",
    redsyscode: "MV",
    schemeAgencyId: "L100027"
  },
  {
    name: "Niedersachsen",
    redsyscode: "NI",
    schemeAgencyId: "L100040"
  },
  {
    name: "Nordrhein-Westfalen",
    redsyscode: "NW",
    schemeAgencyId: "L100002"
  },
  {
    name: "Rheinland-Pfalz",
    redsyscode: "RP",
    schemeAgencyId: "L100039"
  },
  {
    name: "Saarland",
    redsyscode: "SL",
    schemeAgencyId: "L100010"
  },
  {
    name: "Sachsen",
    redsyscode: "SN",
    schemeAgencyId: "L100009"
  },
  {
    name: "Sachsen-Anhalt",
    redsyscode: "ST",
    schemeAgencyId: "L100008"
  },
  {
    name: "Schleswig-Holstein",
    redsyscode: "SH",
    schemeAgencyId: "L100012"
  },
  {
    name: "Thüringen",
    redsyscode: "TH",
    schemeAgencyId: "L100038"
  },
  {
    name: "Industrie- und Handelskammer",
    redsyscode: "IHK",
    schemeAgencyId: ""
  },
  {
    name: "FIT-Connect",
    redsyscode: "FC",
    schemeAgencyId: ""
  }
]
