import * as React from "react"

//Komponente zur Eingabe der Validierungsparameter
interface ReportProps {
  report: string
}

export const Report: React.FC<ReportProps> = ({report}) => {
  return (
    <div className="report-area">
      <div className="report-area-inner">{report}</div>
    </div>
  )
}
