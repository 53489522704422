import React from "react"
import {StatistikenResponse} from "../../models/StatistikenResponse"
import {getRequest} from "../../services/HttpService"

const Table: React.FC = () => {
  const [data, setData] = React.useState<StatistikenResponse[]>([])
  const [status, setStatus] = React.useState<"ERROR" | "FORBIDDEN" | "OK" | "EMPTY">("EMPTY")

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getRequest<StatistikenResponse[]>("v1/archivvalidator/uebersicht", {
          handleResponseError: response => {
            if (response.status === 403) {
              setStatus("FORBIDDEN")
            } else {
              setStatus("ERROR")
            }
          }
        })
        if (response?.length === 0) {
          setStatus("EMPTY")
        }
        if (response) {
          setData(response)
          setStatus("OK")
        }
      } catch (error) {
        setStatus("ERROR")
        console.error("Request failed", error)
      }
    }

    fetchData()
  }, [])

  if (status === "EMPTY") {
    return (
      <div className="wrapper-message">
        <p className={"error-text"}>Für diesen Account liegen keine Validierungsergebnisse vor.</p>
      </div>
    )
  }

  if (status === "FORBIDDEN") {
    return (
      <div className="wrapper-message">
        <p className={"error-text"}>
          Dieser Account hat keine Berechtigungen Statistiken von Bundesländern zu sehen. Falls dieser Account diese
          haben sollte oder Sie einen eigenen Account benötigen, bitte melden Sie sich bei
          <br />
          <a href="mailto:dataportanbindungssupportpvog@dataport.de">dataportanbindungssupportpvog@dataport.de</a>
        </p>
      </div>
    )
  }

  if (status === "ERROR") {
    return (
      <div className="wrapper-message">
        <p className={"error-text"}>Fehler beim Abfragen der Daten!</p>
      </div>
    )
  }

  const formatSeccondsToDuration = (value: number) => {
    return new Date(value * 1000).toLocaleTimeString("de-DE", {
      timeZone: "UTC",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    })
  }
  const dateFormatter = (value: string) => {
    return new Date(value).toLocaleDateString("de-DE", {
      timeZone: "UTC",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      year: "2-digit",
      month: "2-digit",
      day: "2-digit"
    })
  }

  return (
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Gesamt</th>
          <th>Fehlerhaft</th>
          <th>Warnungen</th>
          <th>Hinweise</th>
          <th>Start Zeit</th>
          <th>Dauer</th>
        </tr>
      </thead>
      <tbody>
        {data.map(row => (
          <tr key={row.id}>
            <td>{row.landName}</td>
            <td>{row.gesamt}</td>
            <td>{row.fehlerhaft}</td>
            <td>{row.warnungen}</td>
            <td>{row.hinweise}</td>
            <td>{dateFormatter(row.startZeit)}</td>
            <td>{formatSeccondsToDuration(row.dauer)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default Table
