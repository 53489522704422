import React, {useEffect} from "react"
import {useDelayContext} from "../../context/useDelayContext"
import {useInterval} from "../../pages/hooks/useInterval"

const FortschrittReconnectText = () => {
  const {delay, getTryCount, maxRetryCount} = useDelayContext()
  const [countdown, setCountdown] = React.useState(delay / 1000)

  useInterval(() => setCountdown(prev => Math.max(0, prev - 1)), 1000, countdown > 0)

  useEffect(() => {
    setCountdown(delay / 1000)
  }, [delay])

  return (
    <>
      Fehler bei der Verbindung. Es wird versucht die Verbindung wieder herzustellen...
      <br />
      Nächster Verbindungsversuch in {countdown} Sekunden. (Versuch {getTryCount()} / {maxRetryCount})
    </>
  )
}
export {FortschrittReconnectText}
