import React from "react"
import {createRoot} from "react-dom/client"
import {Delay} from "./components/ausgabe/Delay"
import {Validierung} from "./pages/Validierung"
import "./index.css"

declare global {
  interface Window {
    env: {
      BACKEND_URL: string
      HTTP_PROTOCOL_ENABLED: string
      KEYCLOAK_SERVER_URL: string
      KEYCLOAK_REALM: string
      KEYCLOAK_CLIENT_ID: string
    }
  }
}

const container = document.getElementById("root") as Element
if (container) {
  createRoot(container).render(
    <React.StrictMode>
      <Delay>
        <Validierung />
      </Delay>
    </React.StrictMode>
  )
}
