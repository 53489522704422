import React, {useEffect, useState} from "react"
import {KomplettReportResponse} from "../../models/ReportResponse"
import {getRequest} from "../../services/HttpService"
import {getKeycloak} from "../../keycloak/keycloak"
import {redaktionsSystemsArray} from "../../defaultParameter/redaktionsSystemsArray"

export const DownloadReports: React.FC = () => {
  const [loading, setLoading] = useState<boolean[]>([])
  const [ids, setIds] = useState<string[]>([])
  const [error, setError] = useState<boolean[]>([])

  const fetchIds = async () => {
    try {
      const schemeAgencysIds: string[] = (await getKeycloak()).tokenParsed?.schemeAgencyIds || []
      setIds(schemeAgencysIds)
      setError(new Array(schemeAgencysIds.length).fill(false))
      setLoading(new Array(schemeAgencysIds.length).fill(false))
    } catch (error) {
      console.error("Error fetching IDs:", error)
    }
  }

  useEffect(() => {
    fetchIds()
  }, [])

  const handleDownload = async (id: string, index: number) => {
    const updateLoading = [...loading]
    updateLoading[index] = true
    setLoading(updateLoading)
    try {
      const response = await getRequest<KomplettReportResponse>(`v2/archivvalidator/komplettreport/neuester/${id}`, {
        handleResponseError: response => {
          const updatedError = [...error]
          updatedError[index] = true
          setError(updatedError)
          throw new Error(`Failed to fetch data for ID: ${id} with response ${response}`)
        }
      })
      // Ansonsten wird eine Datei mit "null" runtergeladen
      if (!response) {
        const updatedError = [...error]
        updatedError[index] = true
        setError(updatedError)
        return null
      }

      // Create a Blob from the JSON data
      const file = new Blob([JSON.stringify(response)], {type: "application/json"})

      // Create a hidden anchor element to trigger the download
      const element = document.createElement("a")
      element.href = URL.createObjectURL(file)
      element.download = `${schemeAgencyIdToName(id)}.json`

      // Programmatically trigger the download
      document.body.appendChild(element)
      element.click()

      // Clean up by removing the element
      document.body.removeChild(element)
    } catch (error) {
      console.error("Error during download:", error)
    } finally {
      const updateLoading = [...loading]
      updateLoading[index] = false
      setLoading(updateLoading)
    }
  }

  const schemeAgencyIdToName = (id: string) => {
    const foundRedSys = redaktionsSystemsArray.find(redSys => {
      return redSys.schemeAgencyId === id
    })
    if (foundRedSys) {
      return foundRedSys.name
    }
    return "Kein Bundesland gefunden zu: " + id
  }

  if (ids.length === 0) {
    return null
  }

  return (
    <div>
      <h3> Download des letzten Validierungsreports: </h3>

      <ul className="button-list">
        {ids.map((id, index) => (
          <li key={id} className="button-list-item">
            <button
              className="download-list-button"
              onClick={() => handleDownload(id, index)}
              disabled={loading[index] || error[index]}>
              {loading[index]
                ? "Wird heruntergeladen..."
                : error[index]
                  ? "Fehler beim Download."
                  : schemeAgencyIdToName(id)}
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}
