import React from "react"
import {DelayContext} from "../../context/DelayContext"

interface DelayProps {
  children: React.ReactNode
}
export const Delay = ({children}: DelayProps) => {
  // delay times in ms
  const baseDelay = 2 * 1000
  const firstDelayIncrement = 5 * 1000
  const secondDelayIncrement = 60 * 1000
  const maxDelayIncrement = 2 * 60 * 1000
  const delayTimes = [baseDelay, firstDelayIncrement, secondDelayIncrement, maxDelayIncrement]
  const maxRetryCount = delayTimes.length - 1
  const [delay, setDelay] = React.useState<number>(baseDelay)

  const resetRequestDelay = () => {
    setDelay(baseDelay)
  }

  const isMaxDelay = (): boolean => delay === maxDelayIncrement

  const incrementDelay = () => {
    if (!isMaxDelay()) {
      const currentIndex = delayTimes.indexOf(delay)
      setDelay(delayTimes[currentIndex + 1])
    }
  }

  const getTryCount = (): number => delayTimes.indexOf(delay)

  return (
    <DelayContext.Provider value={{delay, incrementDelay, resetRequestDelay, isMaxDelay, getTryCount, maxRetryCount}}>
      {children}
    </DelayContext.Provider>
  )
}
