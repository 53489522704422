import React from "react"

export const useInterval = (callback: () => void, delay: number, isRunning: boolean) => {
  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    if (isRunning) {
      const intervalId = setInterval(callback, delay)
      return () => clearInterval(intervalId)
    }
  }, [callback, delay, isRunning])
}
