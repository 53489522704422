import * as React from "react"
import logo from "../../assets/img/dataport.svg"
import {WeitereInformationen} from "./WeitereInformationen"
import {Statistiken} from "./Statistiken"
import {getKeycloak} from "../../keycloak/keycloak"

export const Header = () => {
  const [isInfoVisible, setIsInfoVisible] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const [isStatisticVisible, setIsStatisticVisible] = React.useState(false)

  const toggleInfo = () => {
    setIsInfoVisible(prev => !prev)
  }

  const toggleStatistic = () => {
    setIsStatisticVisible(prev => !prev)
  }
  const logout = async () => {
    setIsLoading(true)
    ;(await getKeycloak()).logout()
    setIsLoading(false)
  }

  return (
    <header className="header">
      <img src={logo} className="dataport-logo" alt="Dataport" />
      <span className="title">XZUFI-Validator - Beta</span>
      <button className="logout-button" onClick={logout} disabled={isLoading}>
        Logout
      </button>
      <button className="info-button" onClick={toggleInfo}>
        Weitere Informationen
      </button>
      <button className="statistic-button" onClick={toggleStatistic}>
        Validierungsstatistik
      </button>
      <div style={{visibility: isInfoVisible ? "visible" : "hidden"}} className="version-info">
        <WeitereInformationen closeInfo={toggleInfo} />
      </div>
      <div style={{visibility: isStatisticVisible ? "visible" : "hidden"}} className="statistic-info">
        <Statistiken closeStatistiken={toggleStatistic} />
      </div>
    </header>
  )
}
