import React from "react"
import {ValidierungsAnfrageStatus} from "../../models/ValidierungsAnfrageResponse"

interface FortschrittWrapperProps {
  status: ValidierungsAnfrageStatus
  children: React.ReactNode
}

const errorStates: ValidierungsAnfrageStatus[] = [
  "ERROR_CONNECTION_LOST",
  "ERROR_BAD_REQUEST",
  "ERROR_INTERNAL_BACKEND",
  "ERROR_NO_BACKEND_RESPONSE",
  "ERROR_VALIDATION_ID_NOT_FOUND",
  "ERROR_REPORT_REQUEST_BEFORE_FINISHED"
]

const FortschrittWrapper = ({status, children}: FortschrittWrapperProps) => {
  const isError = errorStates.includes(status)
  return (
    <div className="wrapper-message">
      <p className={isError ? "error-text" : ""}>{children}</p>
    </div>
  )
}
export {FortschrittWrapper}
