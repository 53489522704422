import * as React from "react"
import Table from "./Table"
import {DownloadReports} from "./DownloadReports"

interface StatistikenProps {
  closeStatistiken: () => void
}

export const Statistiken: React.FC<StatistikenProps> = ({closeStatistiken}) => {
  return (
    <div>
      <button className="close" onClick={closeStatistiken}></button>
      <h1 style={{textAlign: "center"}}>Validierungen</h1>
      <DownloadReports />
      <h3>Statistiken</h3>
      <div className="description-text">
        Hier werden die Ergebnisse der regelmäßigen Validierungen angezeigt, die automatisch durchgeführt werden. Die
        manuell angestoßenen Validierungen werden momentan nicht gespeichert und werden hier nicht angezeigt.
      </div>
      <Table />
    </div>
  )
}
