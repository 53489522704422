import React from "react"
interface WeitereXZufiVersionenInformationenProps {
  xZufiVersionen: string[]
}

const WeitereXZufiVersionenInformationen = ({xZufiVersionen}: WeitereXZufiVersionenInformationenProps) => (
  <ul>
    {xZufiVersionen.map((version, index) => (
      <li key={`xZufiVersion-${index}`}>
        <div className="entry-wrapper">
          <span className="entry">{version}</span>
          <div className="clear" />
        </div>
      </li>
    ))}
  </ul>
)
export {WeitereXZufiVersionenInformationen}
